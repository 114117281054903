<template>
  <div>
    USER invited you to join ORGANIZATION .

    <div class="lg" v-if="$store.state.userToken">
      <b-button @click="acceptInvite">Accept</b-button>
    </div>
    <div class="signup form" v-else>
      <!-- signup form buefy -->
      <b-field label="Email" class="field">
        <b-input v-model="email" type="email" placeholder="Enter your email" required></b-input>
      </b-field>
      <!-- desired username  @ and password -->
      <b-field label="Username" class="field">
        <b-input v-model="username" type="text" placeholder="Enter your username" required></b-input>
      </b-field>
      <b-field label="Password" class="field">
        <b-input v-model="password" type="password" placeholder="Enter your password" required></b-input>
      </b-field>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      email: "",
      username: "",
      password: "",
      inviteId: "", // Add the invite ID to data
      invite: {},
    };
  },
  //on mounted fetch invite id dat from the api using the query secret.
  mounted() {
    // Get the invite ID from the route
    const inviteId = this.$route.param.inviteId;

    // Make a GET request to the server to get the invite data
    window.API.getInvite(inviteId).then((res) => {
      // If the request is successful, set the email and invite ID
      this.invite = res;
      //this.email = res.email;
      //this.inviteId = res.inviteId;
    });
  },

  methods: {
    async acceptInvite() {
      const data = {
        email: this.email,
        username: this.username,
        password: this.password,
        inviteId: this.inviteId, // Include the invite ID in the data payload
      };

      // Make a POST request to the server to accept the invite and create a new account
      window.API.acceptInvite(data).then((res) => {
        // If the request is successful, log the user in
        this.$store.commit("setUserToken", res.token);
        this.$store.commit("setUser", res.user);
        this.$router.push({ name: "Home" });
      });
    },
  },
};
</script>
